/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import {
	PauseRounded,
	PlayArrowRounded,
	VolumeDownRounded,
	VolumeUpRounded
} from "@mui/icons-material";
import { Box, CircularProgress, Icon, IconButton, Slider, Stack } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useEffect, useState } from "react";

const SoundPlayer = ({ src }) => {
	const [audio, setAudio] = useState(null);
	const [playing, setPlaying] = useState(false);

	const mainIconColor = "#000";
	const lightIconColor = "rgba(0, 0, 0, 0.4)";

	const [position, setPosition] = useState(audio?.currentTime || 0);
	const [duration, setDuration] = useState(audio?.duration || 0);
	const [volume, setVolume] = useState(50);
	const [loading, setLoading] = useState(true);

	const [error, setError] = useState(false);

	const toggle = () => setPlaying(!playing);

	function formatDuration(value) {
		let minute = Math.floor(value / 60);
		let secondLeft = value - minute * 60;
		secondLeft = Math.floor(secondLeft);
		return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
	}

	useEffect(() => {
		if (src) {
			setAudio(new Audio(src));
		} else {
			if (audio) {
				audio.pause();
				audio.currentTime = 0;
			}
			setAudio(null);
			setPlaying(false);
			setPosition(0);
			setDuration(0);
			setVolume(50);
			setLoading(true);
			setError(false);
		}
	}, [src]);

	useEffect(() => {
		if (audio) {
			playing ? audio.play() : audio.pause();
		}
	}, [playing]);

	useEffect(() => {
		if (!audio) return;

		audio.addEventListener("loadeddata", () => {
			setDuration(audio.duration);
			audio.volume = 0.5;
			setLoading(false);
		});

		audio.addEventListener("timeupdate", () => {
			setPosition(audio.currentTime);
		});

		audio.addEventListener("error", e => {
			setError(true);
		});
	}, [audio]);

	if (error) {
		return (
			<MDBox
				width="40vw"
				p={2}
				borderRadius="xxl"
				bgColor="white"
				display="flex"
				justifyContent="center"
				alignItems="center"
				shadow="md"
			>
				<MDBox display="flex" alignItems="center">
					<Icon>error_outline</Icon>&nbsp;
					<MDTypography variant="body2">{i18n.t("ERRORS.loadSoundFile")}</MDTypography>
				</MDBox>
			</MDBox>
		);
	}

	if (loading) {
		return (
			<MDBox
				width="40vw"
				p={2}
				borderRadius="xxl"
				bgColor="white"
				display="flex"
				justifyContent="center"
				alignItems="center"
				shadow="md"
			>
				<CircularProgress color="dark" />
			</MDBox>
		);
	} else {
		return (
			<MDBox
				width="40vw"
				p={2}
				borderRadius="xxl"
				bgColor="white"
				display="flex"
				alignItems="center"
				shadow="md"
			>
				<MDBox
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
					mr={2}
				>
					<IconButton aria-label={playing ? "play" : "pause"} onClick={toggle}>
						{!playing ? (
							<PlayArrowRounded fontSize="medium" htmlColor={mainIconColor} />
						) : (
							<PauseRounded fontSize="medium" htmlColor={mainIconColor} />
						)}
					</IconButton>
				</MDBox>

				<MDBox flex="2" mr={2}>
					<Slider
						aria-label="time-indicator"
						size="small"
						value={position}
						min={0}
						step={1}
						max={duration}
						onChange={(_, value) => {
							audio.currentTime = value;
						}}
						sx={{
							color: "rgba(0,0,0,0.87)",
							height: 4,
							"& .MuiSlider-thumb": {
								width: 8,
								height: 8,
								transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
								"&::before": {
									boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)"
								},
								"&:hover, &.Mui-focusVisible": {
									boxShadow: `0px 0px 0px 8px rgb(0 0 0 / 16%)`
								},
								"&.Mui-active": {
									width: 20,
									height: 20
								}
							},
							"& .MuiSlider-rail": {
								opacity: 0.28
							}
						}}
					/>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							mt: -2
						}}
					>
						<Box sx={{ fontSize: "14px", opacity: 0.7 }}>{formatDuration(position)}</Box>
						<Box sx={{ fontSize: "14px", opacity: 0.7 }}>
							-{formatDuration(duration - position)}
						</Box>
					</Box>
				</MDBox>

				<Stack flex="1" spacing={2} direction="row" alignItems="center">
					<VolumeDownRounded htmlColor={lightIconColor} />
					<Slider
						aria-label="Volume"
						value={volume}
						onChange={(_, value) => {
							setVolume(value);
							audio.volume = value / 100;
						}}
						min={0}
						max={100}
						sx={{
							color: "rgba(0,0,0,0.87)",
							"& .MuiSlider-track": {
								border: "none"
							},
							"& .MuiSlider-thumb": {
								width: 24,
								height: 24,
								backgroundColor: "#fff",
								"&::before": {
									boxShadow: "0 4px 8px rgba(0,0,0,0.4)"
								},
								"&:hover, &.Mui-focusVisible, &.Mui-active": {
									boxShadow: "none"
								}
							}
						}}
					/>
					<VolumeUpRounded htmlColor={lightIconColor} />
				</Stack>
			</MDBox>
		);
	}
};

export default SoundPlayer;
