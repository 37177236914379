import React from "react";
import { useDropzone } from "react-dropzone";
import i18n from "i18n";
import _lod from "lodash";

const MyDropzone = ({ setFileError, setSuccessJSON, setJsonData }) => {
	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		accept: { "application/json": [".json"] },
		maxFiles: 1,
		onDrop: acceptedFiles => {
			if (!_lod.isEmpty(acceptedFiles)) {
				setFileError("");

				const file = acceptedFiles[0];
				const reader = new FileReader();

				reader.onload = () => {
					try {
						const parsedData = JSON.parse(reader.result);
						setSuccessJSON(true);
						setJsonData(parsedData);
					} catch (e) {
						setSuccessJSON(false);
						setFileError(i18n.t("SETTINGS.DICTIONARY.jsonParsingJSON"));
						setJsonData({});
					}
				};

				reader.onerror = () => {
					setSuccessJSON(false);
					setFileError(i18n.t("SETTINGS.DICTIONARY.jsonParsingJSON"));
					setJsonData({});
				};

				reader.readAsText(file);
			}
		},
		onDropRejected: fileRejections => {
			fileRejections.forEach(fileRejection => {
				if (fileRejection.errors.some(error => error.code === "too-many-files")) {
					setFileError(i18n.t("SETTINGS.DICTIONARY.jsonMany"));
					setSuccessJSON(false);
					setJsonData({});
				} else if (fileRejection.errors.some(error => error.code === "file-invalid-type")) {
					setFileError(i18n.t("SETTINGS.DICTIONARY.jsonValid"));
					setSuccessJSON(false);
					setJsonData({});
				}
			});
		}
	});

	return (
		<div {...getRootProps({ className: "dropzone" })}>
			<input {...getInputProps()} />
			<p>{i18n.t("SETTINGS.DICTIONARY.dropZoneJSON")}</p>
		</div>
	);
};

export default MyDropzone;
