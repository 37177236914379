/* eslint-disable no-plusplus */

import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Chip,
	Icon,
	IconButton,
	InputAdornment,
	Skeleton,
	Switch,
	Tooltip
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import { Add, Delete, WidthFull, WidthWide } from "@mui/icons-material";
import SettingsActions from "redux-react/actions/settingsActions";

import i18n from "i18n";
import lod_ from "lodash";
import MDButton from "components/Basics/MDButton";
import IconAccordion from "../charts/steps/components/IconAccordion";
import C from "../charts/steps/constants";

const IconsList = ({ icon, setIcon }) => {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);

	const [iconsList, setIconsListn] = useState([]);
	const [defaultIconsList, setDefaultIconsListn] = useState([]);
	const [search, setSearch] = useState("");
	const [searchEmpty, setSearchEmpty] = useState(false);

	useEffect(() => {
		const onSuccess = res => {
			setIconsListn(res.icons);
			setDefaultIconsListn(res.icons);
		};
		dispatch(SettingsActions.getIcons(profile.assistantID, onSuccess));
	}, []);

	const setFilter = text => {
		setSearch(text);
		let copyIcons = lod_.cloneDeep(defaultIconsList);
		copyIcons = copyIcons.filter(element => element.code.includes(text));
		if (lod_.isEmpty(copyIcons)) {
			setSearchEmpty(true);
		} else {
			setSearchEmpty(false);
		}
		setIconsListn(copyIcons);
	};

	return (
		<MDBox>
			{lod_.isEmpty(defaultIconsList) ? (
				<IconAccordion
					open
					forceOpen
					title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
					content={C.PAGE_ICONS_LIST.map((ic, index) => {
						return (
							<IconButton
								key={index}
								onClick={() => {
									setIcon(ic);
								}}
							>
								<Icon color={icon === ic ? "info" : ""} fontSize="large">
									{ic}
								</Icon>
							</IconButton>
						);
					})}
					actions={
						<MDButton onClick={() => setIcon(null)}>
							<Icon>close</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
						</MDButton>
					}
				/>
			) : (
				/* // <Accordion expanded>
					<AccordionSummary expandIcon={null}>
						<MDTypography variant="overline" style={{ paddingRight: "20px" }}>
							{i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
						</MDTypography>
					</AccordionSummary>
					<AccordionDetails> */

				<MDBox
					component="fieldset"
					style={{
						border: "1px solid #d2d6da"
					}}
					borderRadius="md"
					p={1}
				>
					<legend
						style={{
							lineHeight: 1.5,
							fontSize: "0.75rem",
							color: "#7b809a",
							fontWeight: 400,
							margin: "4px",
							padding: "0 4px"
						}}
					>
						{i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
					</legend>

					<MDBox style={{ display: "flex", flexDirection: "column" }}>
						{/* Search input */}
						<MDBox
							display="flex"
							flexDirection="row"
							alignItems="center"
							shadow="md"
							p={1}
							borderRadius="md"
						>
							{icon && (
								<Icon color="info" fontSize="large">
									{icon}
								</Icon>
							)}

							{!icon && (
								<Skeleton variant="circular" width={30} height={30} sx={{ mr: 0.5, ml: 0.5 }} />
							)}

							<MDInput
								sx={{ ml: 1 }}
								fullWidth
								label="Rechercher un icon ..."
								size="small"
								onChange={e => {
									setFilter(e.target.value);
								}}
								value={search}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="clear"
												onClick={() => {
													setFilter("");
												}}
											>
												<Icon>close</Icon>
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</MDBox>
						{/* Icons list */}
						<MDBox style={{ height: "200px", overflow: "auto" }} sx={{ mt: 1 }}>
							{searchEmpty ? (
								<MDBox
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										height: "100%"
									}}
								>
									<MDTypography variant="overline" style={{ paddingRight: "20px" }}>
										{i18n.t("SETTINGS.noFilterIcons")}
									</MDTypography>
								</MDBox>
							) : (
								iconsList.map((ic, index) => {
									return (
										<Tooltip key={index} title={ic.code} placement="bottom">
											<IconButton
												key={index}
												onClick={() => {
													setIcon(ic.code);
												}}
											>
												<Icon color={icon === ic.code ? "info" : ""} fontSize="large">
													{ic.code}
												</Icon>
											</IconButton>
										</Tooltip>
									);
								})
							)}
						</MDBox>
					</MDBox>
				</MDBox>
				// 	</AccordionDetails>
				// 	<AccordionActions>
				// 		<MDButton onClick={() => setIcon(null)}>
				// 			<Icon>close</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
				// 		</MDButton>
				// 	</AccordionActions>
				// </Accordion>
			)}
		</MDBox>
	);
};

export default IconsList;
