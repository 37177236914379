import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";

const InputDate = ({ label, value, onChange, disabled, error }) => {
	return (
		<MDBox mt={1}>
			<MDDatePicker
				type="date"
				label={label}
				value={value}
				onChange={e => onChange(e)}
				options={{
					time_24hr: true,
					dateFormat: "d M Y"
				}}
				input={{
					placeholder: `${label} `,
					style: { width: "100%" }
				}}
				disabled={disabled}
			/>
		</MDBox>
	);
};
export default InputDate;
