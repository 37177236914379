/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
/**
 * Main application for forms
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import i18n from "i18n";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { display } from "redux-react/reducers/snackBarReducer";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import { COLLECTIONS } from "../constants";

/**
 *
 * @param {Object} route
 *  - route : actual route
 * @returns
 */
export default function PromptStudioProcessPage({ route }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [confirmDelete, setConfirmDelete] = useState(false);

	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Page Charts
	const [charts, setCharts] = useState([]);

	const [selectedItem, setSelectedItem] = useState({});

	const [reloadTable, setReloadTable] = useState(false);

	const [promptTemplates, setPromptTemplates] = useState(null);

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.customType || chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					null,
					res => resolve(res.charts)
				)
			);
		});
	}
	/**
	 * Get filters from back
	 */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/**
	 * Load charts from back
	 */
	async function loadCharts() {
		// Get all filters from back
		let pageFilters = await getPageFilters();
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		setChartsLoading(false);
	}

	/**
	 * Create
	 */
	const addProcessAction = () => {
		navigate("/prompt-studio/process/edit");
	};

	/**
	 * Edit
	 */
	const editProcessAction = code => {
		navigate(`/prompt-studio/process/edit?id=${code}`);
	};

	/**
	 * Delete
	 */
	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
	};

	/**
	 * Duplicate
	 */
	const duplicateProcessAction = values => {
		dispatch(
			FormActions.duplicateItem(
				{
					query: { code: values.code },
					collection: "llmProcess",
					updateFields: ["code"]
				},
				res => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateSuccess"),
							type: "success"
						})
					);
					setReloadTable(!reloadTable);
				},
				err => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateError"),
							type: "error"
						})
					);
				}
			)
		);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteItem = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			dispatch(FormActions.deleteItem(selectedItem._id, "llmProcess", { delete: true }, onSuccess));
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setChartsLoading(true);
		loadCharts();
		// load promptTemplate
		dispatch(
			FormActions.getItemsFromCollection(COLLECTIONS.promptTemplate, {}, res => {
				setPromptTemplates(res?.items || []);
			})
		);
	}, [profile.selectedAssistant.assistantID, route]);

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox>
					<DashboardNavbar
						filters={[
							<>
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="contained"
									color="info"
									onClick={addProcessAction}
									disabled={promptTemplates === null}
								>
									<Icon>add</Icon>&nbsp;{t("PROMPT.PROCESS.newProcess")}
								</MDButton>
							</>
						]}
					/>
				</MDBox>
				{/*
				 * Process list
				 */}
				{getChartsData(["paginedList"]).map((chart, index) => {
					return (
						<DefaultDataTable
							noHeader
							id={chart.code}
							filters={getLocalStorageBackValues(
								profile.selectedAssistant.assistantID,
								route.route,
								filters
							)}
							reloadTable={reloadTable}
							dictionary={chart.dictionary}
							form={route.form}
							list={chart}
							pagination={chart.pagination}
							canSearch
							key={index}
							table={chart.data}
							display={chart.request.attributesDisplay}
							actions={[
								<IconButton
									handleclick={({ values }) => {
										editProcessAction(values.code);
									}}
								>
									<Tooltip placement="top" title={t("SETTINGS.edit")}>
										<Icon>edit</Icon>
									</Tooltip>
								</IconButton>,
								<IconButton
									handleclick={({ values }) => {
										duplicateProcessAction(values);
									}}
								>
									<Tooltip placement="top" title={t("SETTINGS.duplicate")}>
										<Icon>copy</Icon>
									</Tooltip>
								</IconButton>,
								<IconButton
									handleclick={({ values }) => {
										actionDeleteHandle(values);
									}}
								>
									<Tooltip placement="top" title={t("SETTINGS.delete")}>
										<Icon>delete</Icon>
									</Tooltip>
								</IconButton>
							]}
						/>
					);
				})}

				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
						<DialogTitle>{`${t("FORMS.LABELS.delete")} ${selectedItem.name}`}</DialogTitle>
						<DialogContent>{`${t("FORMS.LABELS.confirmDelete")} ${selectedItem.name}`}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeDeleteHandle} variant="outlined" color="info">
								{t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteItem} color="info" variant="contained" autoFocus>
								{t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
			</DashboardLayout>
		);
}
