import {
	Card,
	CircularProgress,
	Divider,
	FormControl,
	Icon,
	IconButton,
	MenuItem,
	Select,
	Tab,
	Tabs,
	Tooltip
} from "@mui/material";
import { display } from "redux-react/reducers/snackBarReducer";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import FilesActions from "redux-react/actions/filesActions";
import { useDispatch, useSelector } from "react-redux";
import ConversationTab from "./Tabs/ConversationTab";
import AnalyzeTab from "./Tabs/AnalyzeTab";
import CustomerTab from "./Tabs/CustomerTab";

const PhoneCall = ({
	phoneCall,
	visualiseConversationLoading,
	closePannel,
	fullScreen,
	setFullScreen,
	reload,
	tab,
	setTab
}) => {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const isConversationAnalysed = Boolean(phoneCall?.dictionary?.context?.analyse);

	const changeReviewState = reviewState => {
		dispatch(
			display({
				message: "Pas encore implémenté",
				type: "warning"
			})
		);
		// dispatch(
		// 	FilesActions.changeReviewState(profile?.assistantID, phoneCall?.code, reviewState, res => {
		// 		reload(res?.conversation);
		// 	})
		// );
	};

	const SELECT_COLOR = {
		waiting: "black",
		ongoing: "white",
		resolved: "white"
	};

	const SELECT_BG_COLOR = {
		waiting: "light",
		ongoing: "info",
		resolved: "success"
	};

	return (
		<MDBox
			className="rightPannel"
			sx={{ ml: fullScreen ? 0 : 2 }}
			style={{
				width: fullScreen ? "100%" : "30vw"
			}}
		>
			<Card sx={{ p: 2, height: "100%" }}>
				{visualiseConversationLoading ? (
					<MDBox
						style={{
							height: "100%"
						}}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<CircularProgress color="info" size={60} />
					</MDBox>
				) : (
					<>
						<MDBox display="flex" alignItems="center" justifyContent="space-between">
							<MDBox display="flex" justifyContent="start" flexDirection="column">
								<MDTypography variant="h6">Conversation</MDTypography>
								<MDTypography variant="body2" fontSize="small">
									{phoneCall.fID}
								</MDTypography>
							</MDBox>
							<MDBox display="flex">
								{/* Review state */}
								<MDBox
									bgColor={SELECT_BG_COLOR[phoneCall.state]}
									sx={{ minWidth: 100 }}
									borderRadius="md"
								>
									<FormControl fullWidth size="small">
										<Select
											labelId="select-label"
											id="select"
											value={phoneCall.state}
											onChange={e => {
												changeReviewState(e.target.value);
											}}
											sx={{
												boxShadow: "none",
												".MuiOutlinedInput-notchedOutline": { border: 0 },
												"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
													border: 0
												},
												"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
													border: 0
												},
												".MuiSelect-icon": {
													display: "block",
													fill: SELECT_COLOR[phoneCall.state]
												},
												".MuiSelect-select": {
													color: `${SELECT_COLOR[phoneCall.state]} !important`,
													fontWeight: "bold"
												}
											}}
										>
											{/* {whitelist.map((option, index) => ( */}
											<MenuItem value="waiting">En attente</MenuItem>
											<MenuItem value="ongoing">En cours</MenuItem>
											<MenuItem value="resolved">Résolue</MenuItem>
											{/* ))} */}
										</Select>
									</FormControl>
								</MDBox>
								{/* FullScreen */}
								{!fullScreen && (
									<Tooltip placement="top" title="Plein écran">
										<IconButton onClick={() => setFullScreen(!fullScreen)}>
											<Icon>fullscreen</Icon>
										</IconButton>
									</Tooltip>
								)}
								{fullScreen && (
									<Tooltip placement="top" title="Plein écran">
										<IconButton onClick={() => setFullScreen(!fullScreen)}>
											<Icon>fullscreen_exit</Icon>
										</IconButton>
									</Tooltip>
								)}
								{/* Close */}
								<Tooltip placement="top" title="Fermer">
									<IconButton onClick={closePannel}>
										<Icon>close</Icon>
									</IconButton>
								</Tooltip>
							</MDBox>
						</MDBox>
						{!fullScreen && (
							<MDBox>
								<Tabs
									value={tab}
									onChange={(e, v) => {
										setTab(v);
									}}
									aria-label="basic tabs example"
								>
									<MDBox flex="1" onClick={() => setTab(0)}>
										<Tab style={{ width: "100%" }} label="Conversation" />
									</MDBox>
									<Tooltip
										title={!isConversationAnalysed ? "Vous devez d'abord analyser l'appel" : ""}
										placement="top"
									>
										<MDBox flex="1" onClick={() => (isConversationAnalysed ? setTab(1) : {})}>
											<Tab
												style={{ width: "100%" }}
												label="Client"
												disabled={!isConversationAnalysed}
											/>
										</MDBox>
									</Tooltip>
									<Tooltip
										title={!isConversationAnalysed ? "Vous devez d'abord analyser l'appel" : ""}
										placement="top"
									>
										<MDBox flex="1" onClick={() => (isConversationAnalysed ? setTab(2) : {})}>
											<Tab
												style={{ width: "100%" }}
												label="Analyse"
												disabled={!isConversationAnalysed}
											/>
										</MDBox>
									</Tooltip>
								</Tabs>
							</MDBox>
						)}
						{/* If w're not in fullscreen => Use tabs */}
						{!fullScreen && (
							<>
								{/* Conversation */}
								{tab === 0 && <ConversationTab phoneCall={phoneCall} />}
								{/* Customer */}
								{tab === 1 && isConversationAnalysed && <CustomerTab phoneCall={phoneCall} />}
								{/* Analyse */}
								{tab === 2 && isConversationAnalysed && <AnalyzeTab phoneCall={phoneCall} />}
							</>
						)}
						{fullScreen && (
							<MDBox
								display="flex"
								style={{
									height: "100%"
								}}
							>
								<MDBox
									flex={1}
									mr={1}
									style={{
										height: "90%",
										overflowY: "auto"
									}}
								>
									<ConversationTab phoneCall={phoneCall} />
								</MDBox>
								<Divider orientation="vertical" />
								<MDBox
									mr={1}
									ml={1}
									flex={1}
									style={{
										height: "90%",
										overflowY: "auto"
									}}
								>
									<CustomerTab phoneCall={phoneCall} />
								</MDBox>
								<Divider orientation="vertical" />
								<MDBox
									ml={1}
									flex={1}
									style={{
										height: "90%",
										overflowY: "auto"
									}}
								>
									<AnalyzeTab phoneCall={phoneCall} />
								</MDBox>
							</MDBox>
						)}
					</>
				)}
			</Card>
		</MDBox>
	);
};

export default PhoneCall;
