import { Divider, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import { useState } from "react";
import DownloadList from "./DownloadList";

const ListOptions = ({ chart, data, title }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openDownloadList, setOpenDownloadList] = useState(false);

	const renderMenu = () => {
		return (
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
				{/* Actions */}
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">Actions</MDTypography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setOpenDownloadList(true);
						setAnchorEl(null);
					}}
				>
					<Icon>download</Icon>&nbsp;Télécharger
				</MenuItem>
			</Menu>
		);
	};

	return (
		<>
			<Tooltip placement="top" title="Actions sur la liste">
				<IconButton
					onClick={e => {
						setAnchorEl(e.currentTarget);
					}}
				>
					<Icon>more_vert</Icon>
				</IconButton>
			</Tooltip>
			{renderMenu()}
			{/* Download list */}
			{chart && (
				<DownloadList
					open={openDownloadList}
					onClose={() => setOpenDownloadList(false)}
					data={data}
					title={title}
					chart={chart}
				/>
			)}
		</>
	);
};

export default ListOptions;
