/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unreachable */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */

import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import FormActions from "redux-react/actions/formAction";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";

import InputBoolean from "./Inputs/InputBoolean";
import InputDelay from "./Inputs/InputDelay";
import InputPhone from "./Inputs/InputPhone";
import InputDate from "./Inputs/InputDate";
import InputEmail from "./Inputs/InputEmail";
import InputString from "./Inputs/InputString";
import InputNumber from "./Inputs/InputNumber";
import InputCode from "./Inputs/InputCode";
import InputImageList from "./Inputs/InputImageList";

/**
 * Component for input
 */
export const FormInput = ({
	item,
	path,
	datas,
	handleChange,
	regexEmail = "",
	listOfApis = [],
	errorUniqueFields
}) => {
	let type = item.type;
	let label = item.label.fr;
	let codes = item.codes || [];

	let value = lod_.get(datas, path);
	let disabled = !(item?.actions?.edit ?? true);
	let error = errorUniqueFields?.includes(path);

	const onChange = value => {
		handleChange(path, value);
	};

	switch (type) {
		case "imageList":
			return (
				<InputImageList
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "boolean":
			return (
				<InputBoolean
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "phone":
			return (
				<InputPhone
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "date":
		case "datetime":
			return (
				<InputDate
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "email":
			return (
				<InputEmail
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					regexEmail={regexEmail}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "string":
			return (
				<InputString
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "number":
			return (
				<InputNumber
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "delay":
			return (
				<InputDelay
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "code":
			return (
				<InputCode
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					codes={codes}
					path={path}
				/>
			);
		default:
			return (
				<MDBox mt={1}>
					<MDInput
						disabled={disabled}
						error={error}
						type={type}
						className="dialogInput"
						label={label}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				</MDBox>
			);
	}
};
/**
 * Component for create a form from a dictionary
 */
export function LittleForm({
	object,
	metadatasSkeleton,
	handleChange,
	level = 0,
	path = null,
	listAPIs = null,
	errorUniqueFields
}) {
	const [regexEmail, setRegexEmail] = useState("");
	const [listOfApis, setListOfApis] = useState([]);

	let keys = Object.keys(object);
	const dispatch = useDispatch();
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);

	useEffect(() => {
		const onSuccess = res => {
			setRegexEmail(res.regex);
		};
		const onSuccessApis = res => {
			setListOfApis(res?.apisList || []);
		};

		if (regexEmail === "") {
			dispatch(FormActions.getRegexLittleForm("email", onSuccess));
		}
		if (lod_.isEmpty(listOfApis)) {
			if (listAPIs) {
				setListOfApis(listAPIs);
			} else {
				dispatch(FormActions.getListApis(assistantIDs, onSuccessApis));
			}
		}
	}, []);

	return (
		<div
			style={{
				marginLeft: `${level * 1}rem`
			}}
		>
			{keys.map((key, index) => {
				let item = object[key];
				let display = item.display ?? true;
				if (!display) return null;
				if (item.type === "level") {
					return (
						<div
							key={index}
							style={{
								border: "1px solid #ccc",
								padding: "1rem",
								marginBottom: "1rem",
								borderRadius: "5px"
							}}
						>
							<h3>{item.label.fr}</h3>
							<LittleForm
								object={item.items}
								level={level + 1}
								metadatasSkeleton={metadatasSkeleton}
								handleChange={handleChange}
								path={path ? `${path}.${key}` : key}
								listOfApis={listOfApis}
								errorUniqueFields={errorUniqueFields}
							/>
						</div>
					);
				} else {
					return (
						<MDBox pt={1}>
							<FormInput
								key={index}
								item={item}
								datas={metadatasSkeleton}
								handleChange={handleChange}
								regexEmail={regexEmail}
								path={path ? `${path}.${key}` : key}
								listOfApis={listOfApis}
								errorUniqueFields={errorUniqueFields}
							/>
						</MDBox>
					);
				}
			})}
		</div>
	);
}
/**
 * Create a datas skeleton from a dictionary
 * @param {*} dictionary
 * @param {*} values
 * @returns
 */
export function createDictionarySkeleton(dictionary, values = {}, path = null) {
	if (dictionary.items) {
		dictionary = dictionary.items;
	}

	let metadatas = {};
	let keys = Object.keys(dictionary);

	keys.map(key => {
		let item = dictionary[key];
		let tempPath = `${path ? `${path}.` : ""}${key}`;

		if (item.type === "level") {
			// If it's a level, we need to check childrens
			lod_.set(metadatas, tempPath, createDictionarySkeleton(item.items, values, tempPath));
		} else {
			// Get possible value with full path
			let possibleValue = lod_.get(values, tempPath);
			// Set value
			let defaultValue = possibleValue !== undefined ? possibleValue : "";
			if (defaultValue === null) {
				switch (item.type) {
					case "boolean":
						defaultValue = false;
						break;
					case "number":
						defaultValue = item?.isArray ? [] : 0;
						break;
					default:
						defaultValue = item?.isArray ? [] : "";
						break;
				}
			}
			// Set value on object at actual key
			lod_.set(metadatas, key, defaultValue);
		}
	});

	return metadatas;
}
