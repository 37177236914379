import React from "react";

export function regexPhoneNumber(str) {
	const regex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;
	return regex.test(str.trim().toLowerCase());
}

export function regexMail(str, regex) {
	if (regex) {
		let regexString = new RegExp(regex);
		return regexString.test(str.trim().toLowerCase());
	} else {
		/* eslint-disable-next-line */
		return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str.trim().toLowerCase());
	}
}
