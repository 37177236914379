/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import "./style.css";
import { FormControlLabel, Icon, Radio, Table, TableBody, TableContainer } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { createDictionarySkeleton, FormInput } from "components/Custom/LittleForm";
import lod_ from "lodash";
import { useEffect, useState } from "react";
/**
 * Cell component
 */
const MergeCell = ({ overrideLabel = null, name, contact, finalContact, handleChange }) => {
	let radio = !contact.same || contact.enabled;

	if (contact.value === null || contact.value === undefined || contact.value === "") {
		return <MDBox component="td" p={2}></MDBox>;
	}

	return (
		<MDBox component="td" p={2}>
			{radio && (
				<FormControlLabel
					control={
						<Radio
							value={contact.value}
							checked={contact.value === finalContact[name]}
							onClick={() => {
								handleChange(name, contact.value);
							}}
						/>
					}
					label={overrideLabel ?? contact.value.toString()}
				/>
			)}
			{!radio && (
				<MDTypography
					variant="body2"
					fontSize="medium"
					fontWeight="bold"
					style={{
						opacity: 0.5
					}}
				>
					{contact.value}
				</MDTypography>
			)}
		</MDBox>
	);
};
// Convert mapped item into key->value item
function parsedItem(item) {
	let newItem = {};
	let keys = Object.keys(item);
	for (let key of keys) {
		newItem[key] = item[key].value;
	}
	return newItem;
}
/**
 * Main component
 */
const FusionContent = ({ fusionElements, dictionaryContact, onChange }) => {
	if (!dictionaryContact || lod_.isEmpty(dictionaryContact?.items)) {
		return (
			<MDBox>
				<MDTypography variant="body2" fontSize="small">
					No dictionary contact found
				</MDTypography>
			</MDBox>
		);
	}

	let fusion = fusionElements?.fusion || []; // used to merge technical fields or others fields whoes are not in dictionary
	let mappedContacts = fusionElements?.mappedContacts || []; // value for each field for each contact
	let fusionedContact = fusionElements?.fusionedContact || {}; // the contact resulting from the fusion
	let extraFinalContactValues = fusionElements?.extraFinalContactValues || {}; // extra unique values that will automatically include in fusioned contact

	let dictionaryKeys = Object.keys(dictionaryContact.items);
	// Sort dictionnary and test order if Number
	dictionaryKeys.sort((a, b) => {
		let orderA = dictionaryContact.items[a].order;
		let orderB = dictionaryContact.items[b].order;

		orderA = !lod_.isNil(orderA) && !lod_.isNaN(Number(orderA)) ? Number(orderA) : 0;
		orderB = !lod_.isNil(orderB) && !lod_.isNaN(Number(orderB)) ? Number(orderB) : 0;

		return orderA - orderB;
	});

	const [finalContact, setFinalContact] = useState(fusionedContact);

	useEffect(() => {
		// extraFinalContactValues are merged to finalContact from the backend but here we merge it again to be sure (secutiry)
		onChange({ ...finalContact, ...extraFinalContactValues });
	}, [finalContact]);

	return (
		<TableContainer>
			<Table
				className="fusionContent_table"
				style={{
					borderCollapse: "separate"
				}}
			>
				{/* Table head */}
				<MDBox component="tr" style={{ textAlign: "left" }}>
					<MDBox
						component="th"
						className="fusionContent_table_head_tableFixedCol fusionContent_table_head_row_cell"
						p={2}
						style={{
							borderRight: "2px solid rgb(0 0 0 / 23%)",
							backgroundColor: "white",
							zIndex: 1
						}}
					>
						<MDBox display="flex" alignItems="center">
							<Icon color="info">auto_awesome</Icon>
							<MDBox ml={1}>Résultat</MDBox>
						</MDBox>
					</MDBox>
					{mappedContacts.map((_, index) => {
						return (
							<MDBox key={index} component="th" p={2} className="fusionContent_table_head_row_cell">
								<MDBox display="flex" alignItems="center" justifyContent="space-between">
									<MDBox display="flex" alignItems="center" mr={2}>
										<Icon color="dark">face</Icon>
										<MDBox ml={1}>{`Contact #${index + 1}`}</MDBox>
									</MDBox>
									<MDButton
										variant="contained"
										color="info"
										size="small"
										onClick={() => {
											setFinalContact({});
											setTimeout(() => {
												setFinalContact(parsedItem(mappedContacts[index]));
											}, 0);
										}}
									>
										Sélectionner
									</MDButton>
								</MDBox>
							</MDBox>
						);
					})}
				</MDBox>
				{/* Table body */}
				<TableBody>
					{/* Dictionary part */}
					{dictionaryKeys.map((key, index) => {
						return (
							<MDBox
								component="tr"
								key={index}
								style={{
									backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white"
								}}
							>
								{/* Result cell */}
								<MDBox
									key={index}
									component="td"
									p={2}
									className="fusionContent_table_head_tableFixedCol"
									style={{
										borderRight: "2px solid rgb(0 0 0 / 23%)",
										backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
										zIndex: 1
									}}
								>
									<MDBox fullWidth bgColor="white" borderRadius="md">
										<FormInput
											item={lod_.get(dictionaryContact.items, key) || ""}
											datas={createDictionarySkeleton(dictionaryContact.items, finalContact)}
											handleChange={(path, value) => {
												setFinalContact(prev => {
													let newFinal = lod_.cloneDeep(prev);
													lod_.set(newFinal, path, value);
													return newFinal;
												});
											}}
											path={key}
										/>
									</MDBox>
								</MDBox>
								{mappedContacts.map((contact, index) => {
									return (
										<MergeCell
											name={key.replaceAll(" ", "-")}
											key={index}
											contact={contact[key]}
											finalContact={finalContact}
											handleChange={(name, value) => {
												setFinalContact(prev => {
													let newFinal = lod_.cloneDeep(prev);
													newFinal[name] = value;
													return newFinal;
												});
											}}
										/>
									);
								})}
							</MDBox>
						);
					})}
					{/* Technical fusion part */}
					{fusion.map(({ key, name }, index) => {
						return (
							<MDBox
								component="tr"
								key={index}
								style={{
									backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white"
								}}
							>
								{/* Result cell */}
								<MDBox
									key={index}
									component="td"
									p={2}
									className="fusionContent_table_head_tableFixedCol"
									style={{
										borderRight: "2px solid rgb(0 0 0 / 23%)",
										backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
										zIndex: 1
									}}
								>
									<MDBox>
										<MDTypography variant="body2" fontSize="medium" fontWeight="bold">
											{name}
										</MDTypography>
										<MDTypography variant="caption" fontSize="small">
											{`Code : ${key}`}
										</MDTypography>
									</MDBox>
								</MDBox>
								{mappedContacts.map((contact, index) => {
									return (
										<MergeCell
											overrideLabel="Choisir"
											name={key.replaceAll(" ", "-")}
											key={index}
											contact={contact[key]}
											finalContact={finalContact}
											handleChange={(name, value) => {
												setFinalContact(prev => {
													let newFinal = lod_.cloneDeep(prev);
													newFinal[name] = value;
													return newFinal;
												});
											}}
										/>
									);
								})}
							</MDBox>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default FusionContent;
