import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import { cloneElement, useEffect, useState } from "react";

export default function ConfirmDialogButton({
	component: ComponentProps,
	onCancel = () => {},
	onConfirm: onConfirmProps,
	title: titleProps,
	content: contentProps,
	displayAttribute,
	rowValues
}) {
	const [component, setComponent] = useState(null);
	const [open, setOpen] = useState(false);

	const title = displayAttribute ? `Supprimer "${rowValues[displayAttribute]}"` : titleProps;
	const content = displayAttribute
		? `Êtes-vous sûr de vouloir supprimer "${rowValues[displayAttribute]}" ?`
		: contentProps;

	const handleClose = e => {
		e.preventDefault();
		e.stopPropagation();
		setOpen(false);
		onCancel();
	};

	const onConfirm = e => {
		onConfirmProps(e, rowValues);
		setOpen(false);
	};

	// Give the component the ability to open the dialog
	useEffect(() => {
		const componentWithProps = cloneElement(ComponentProps, {
			onClick: e => {
				e.preventDefault();
				e.stopPropagation();
				setOpen(true);
			}
		});

		setComponent(componentWithProps);
	}, []);

	return (
		<MDBox>
			{component}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MDButton variant="outlined" color="info" onClick={handleClose}>
						Annuler
					</MDButton>
					<MDButton variant="contained" color="info" onClick={onConfirm}>
						Confirmer
					</MDButton>
				</DialogActions>
			</Dialog>
		</MDBox>
	);
}
