/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/prefer-default-export */

import { FmdGood, NearMe } from "@mui/icons-material";
import { Icon, Switch, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import lod_ from "lodash";
import { display } from "redux-react/reducers/snackBarReducer";

const ColorItemLabel = ({ color }) => {
	return (
		<div
			style={{
				height: "3vh",
				width: "3vh",
				borderRadius: "7px",
				backgroundColor: color
			}}
		></div>
	);
};

const createObjectTable = (obj, cell, dictionary, form) => {
	if (!lod_.isArray(obj)) {
		obj = [obj];
	}

	let collection = form?.collectionDefault;
	let attribute = cell?.column?.id;

	if (!collection || !attribute) return null;

	let midPath = collection + "." + attribute;

	let sample = obj[0];
	let sampleKeys = Object.keys(sample);

	return (
		<table>
			<thead>
				<tr>
					{sampleKeys.map((key, index) => {
						let realPath = midPath + "." + key;
						realPath = realPath.split(".")?.join(".items.");
						let fromDictionary = lod_.get(dictionary, realPath);

						if (fromDictionary && fromDictionary.display) {
							return <th key={index}>{fromDictionary.label.fr}</th>;
						} else {
							return null;
						}
					})}
				</tr>
			</thead>
			<tbody>
				{obj.map((row, index) => {
					return (
						<tr key={index}>
							{sampleKeys.map((key, index) => {
								let realPath = midPath + "." + key;
								realPath = realPath.split(".")?.join(".items.");
								let fromDictionary = lod_.get(dictionary, realPath);

								if (fromDictionary && fromDictionary.display) {
									return <td key={index}>{row[key]}</td>;
								} else {
									return null;
								}
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

const createArrayTable = arr => {
	return <div>{arr.join(", ")}</div>;
};

/**
 * ##############################
 * Display special fields in table
 * e.g : colors, icons, images, etc
 * ##############################
 */
export function handleSpecialCell(
	cell,
	rowKey,
	dictionary,
	form,
	chart,
	displayDictionaryName = null
) {
	let dictionaryPath = (displayDictionaryName ?? chart?.request?.collection) + "." + cell.column.id;
	dictionaryPath = dictionaryPath.replaceAll(".", ".items.");
	let dictionaryItem = lod_.get(dictionary, dictionaryPath);

	const TYPE_DATE = ["date", "datetime"];
	const TYPE_DURATION = ["timestamp"];
	const TYPE_IMAGES = ["imageList"];
	const TYPE_CODE = ["code"];

	let filtersLabel = [];

	switch (cell.column.id) {
		case "data.header.sendingDate":
			return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
		case "filters":
			cell.value.map((filter, index) => {
				let realPath = filter.dictionaryPath?.split(".")?.join(".items.");
				let fromDictionary = lod_.get(dictionary, realPath);
				if (fromDictionary && fromDictionary.analytics && fromDictionary.active) {
					filtersLabel.push(fromDictionary.label.fr);
				}
			});
			return <div>{filtersLabel.join(" / ")}</div>;

		case "labels":
			return (
				<MDBox>
					{!lod_.isEmpty(cell.value) ? (
						<>
							{Object.keys(cell.value).map((childItem, key) => {
								return (
									<div style={{ display: "flex" }}>
										<span style={{ fontWeight: "bolder" }}>{childItem} </span>
										<span>{" : " + cell.value[childItem]}</span>
									</div>
								);
							})}
						</>
					) : (
						<MDBox></MDBox>
					)}
				</MDBox>
			);

		case "color":
			return <ColorItemLabel color={cell.value} />;
		case "display.pages":
			return <div>{cell.value.join(", ")}</div>;
		case "images":
			if (!cell.value) return null;
			return <div>voir (bientot dispo)</div>;
		case "createdDate":
			return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
		case "active":
		case "analysed":
			if (!cell.value) {
				return (
					<Icon color="error" fontSize="medium">
						close
					</Icon>
				);
			} else {
				return (
					<Icon color="success" fontSize="medium">
						check
					</Icon>
				);
			}
		default:
			/**
			 * Collections
			 */
			if (cell.column.id === "state" && form?.collectionDefault === "acknowledgmentOfReceipt") {
				switch (cell.value) {
					case "pending":
						return (
							<Tooltip placement="top" title="En attente">
								<Icon fontSize="medium">hourglass_empty</Icon>
							</Tooltip>
						);
					case "sending":
						return (
							<Tooltip placement="top" title="En cours d'envoi">
								<Icon fontSize="medium">schedule_send</Icon>
							</Tooltip>
						);
					case "sent":
						return (
							<Tooltip placement="top" title="Envoyé">
								<Icon fontSize="medium">check</Icon>
							</Tooltip>
						);
					case "error":
						return (
							<Tooltip placement="top" title="En erreur">
								<Icon fontSize="medium">error</Icon>
							</Tooltip>
						);
					case "cancelled":
						return (
							<Tooltip placement="top" title="Annulé">
								<Icon fontSize="medium">cancel</Icon>
							</Tooltip>
						);
					default:
						return cell.render("Cell");
				}
			}
			/**
			 * Dictionary case handling
			 */
			if (dictionaryItem && TYPE_DATE.includes(dictionaryItem.type)) {
				return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
			}
			if (!lod_.isNil(cell?.value) && typeof cell.value === "boolean") {
				return <Switch checked={cell.value} />;
			}

			if (dictionaryItem && TYPE_IMAGES.includes(dictionaryItem.type)) {
				return (
					<MDBox
						component="img"
						src={cell.value}
						style={{
							height: "3rem",
							width: "3rem",
							objectFit: "contain"
						}}
					/>
				);
			}

			if (dictionaryItem && TYPE_CODE.includes(dictionaryItem.type)) {
				let element = dictionaryItem.codes.find(code => code.value === cell.value);
				let label = element ? element.label : cell.value;
				return <span>{label}</span>;
			}
			/**
			 * Default case handling
			 */
			if (lod_.isArray(cell.value)) {
				let sample = cell.value[0];

				if (lod_.isObject(sample)) {
					return createObjectTable(cell.value, cell, dictionary, form);
				} else {
					return createArrayTable(cell.value);
				}
			} else if (lod_.isObject(cell.value)) {
				return createObjectTable(cell.value, cell, dictionary, form);
			} else {
				return cell.render("Cell");
			}
	}
}
