import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import { cloneElement, useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";

export default function ImportJsonDialog({
	component: ComponentProps,
	onCancel = () => {},
	onConfirm: onConfirmProps
}) {
	const [component, setComponent] = useState(null);
	const [open, setOpen] = useState(false);

	const [valid, setValid] = useState(false);

	const [value, setValue] = useState("");

	const handleClose = e => {
		e.preventDefault();
		e.stopPropagation();
		setOpen(false);
		onCancel();
	};

	const onConfirm = () => {
		onConfirmProps({
			value
		});
		setOpen(false);
	};

	useEffect(() => {
		try {
			JSON.parse(value);
			setValid(true);
		} catch {
			setValid(false);
		}
	}, [value]);

	useEffect(() => {
		if (open) {
			setValue("");
			setValid(false);
		}
	}, [open]);

	// Give the component the ability to open the dialog
	useEffect(() => {
		const componentWithProps = cloneElement(ComponentProps, {
			onClick: e => {
				e.preventDefault();
				e.stopPropagation();
				setOpen(true);
			}
		});

		setComponent(componentWithProps);
	}, []);

	return (
		<MDBox>
			{component}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: {
						width: "100%",
						maxWidth: "50vw"
					}
				}}
			>
				<DialogTitle id="alert-dialog-title">{i18n.t("COMPONENT.IMPORT_JSON.title")}</DialogTitle>
				<DialogContent>
					<MDInput
						multiline
						rows={20}
						value={value}
						onChange={e => setValue(e.target.value)}
						placeholder={i18n.t("COMPONENT.IMPORT_JSON.insertJsonHere")}
						fullWidth
						error={!valid}
						success={valid}
					/>
					{!valid && (
						<DialogContentText id="alert-dialog-description">
							{i18n.t("COMPONENT.IMPORT_JSON.unvalidJSON")}
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions>
					<MDButton variant="outlined" color="info" onClick={handleClose}>
						{i18n.t("SETTINGS.cancel")}
					</MDButton>
					<MDButton variant="contained" color="info" disabled={!valid} onClick={onConfirm}>
						{i18n.t("SETTINGS.import")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</MDBox>
	);
}
