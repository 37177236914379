/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Page settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useDispatch, useSelector } from "react-redux";
import SettingsActions from "redux-react/actions/settingsActions";
import ProfileActions from "redux-react/actions/profileActions";
import FormActions from "redux-react/actions/formAction";
import lod_, { set } from "lodash";
import { updatePermissions } from "redux-react/reducers/permissionsReducer";
import { selectAllSortedMenu } from "redux-react/reducers/profileReducer";
import IconsList from "../icons";

/**
 * Component to display a role in the list
 */
const RolesBox = ({ type, name, code, rolesList = [], onChange }) => {
	const isInArray = arr => {
		return arr.includes(code);
	};

	return (
		<MenuItem onClick={() => onChange(code)}>
			<MDBox display="flex" alignItems="center" justifyContent="start">
				<Checkbox checked={isInArray(rolesList)} />
				<MDBox display="flex" flexDirection="column">
					<MDTypography variant="body">{name}</MDTypography>
					{/* <MDTypography variant="caption">({i18n.t(`SETTINGS.${type}`)})</MDTypography> */}
				</MDBox>
			</MDBox>
		</MenuItem>
	);
};

export default function AddPageDialog({
	open,
	handleCloseDialog,
	handleSave,
	rank,
	page = null,
	filtersList = {}
}) {
	const menu = useSelector(selectAllSortedMenu);
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const activeResources = menu
		.filter(item => item.type === "route" && item.subType === "form" && item.form)
		.map(item => item.form.resource);

	/* Page attributes */
	const [name, setName] = useState(null);
	const [type, setType] = useState("route");
	const [icon, setIcon] = useState(null);

	const [form, setForm] = useState({
		active: true,
		collectionDefault: null,
		pageLabel: null
	});

	const [resources, setResources] = useState([]);

	const [filterCode, setFilterCode] = useState(null);
	// -> Roles that will be displayed in the list to be selected
	const [defaultRolesList, setDefaultRolesList] = useState([]);
	// -> Roles that will be selected by the user
	const [rolesList, setRolesList] = useState([]);

	const disabledForType = type => {
		switch (type) {
			case "route":
				if (!name) {
					return true;
				}
				break;
			case "form":
				if (!form.collectionDefault || form.collectionDefault === "none") {
					return true;
				}
				break;
			case "title":
				if (!name) {
					return true;
				}
				break;
			case "divider":
				return false;
			default:
				return false;
		}

		return false;
	};

	const isDisabled = disabledForType(type);
	/**
	 * Close dialog
	 */
	function close() {
		handleCloseDialog();
		setName(null);
		setIcon(null);
		setType("route");
		setFilterCode(null);
		setRolesList([]);
		setForm({
			active: true,
			collectionDefault: null,
			pageLabel: null
		});
	}
	/**
	 * Create the page permission
	 * format be like : "read:page_[type]_[name]"
	 * For dividers, need to create a random ID because they have no name
	 */
	function createPagePermission(type, routeName, list) {
		// If user didn't select any role, return null -> We dont want permission on this page
		if (!list || lod_.isEmpty(list)) return null;
		// If the page is a divider, create a random ID for it
		if (type === "divider") {
			let rdmID = Math.random().toString(36).substring(7);
			return `read:page_${type}_${rdmID}`;
		}
		// Else, return the permission
		return `read:page_${type}_${routeName}`;
	}
	/**
	 * Submit the dialog, create the page
	 */
	function submitSave() {
		let returnObject = {};
		// 1- Create the page permission
		let pagePermission = createPagePermission(
			type,
			name?.toLowerCase().trim().replaceAll(" ", "-"),
			rolesList
		);
		// 2- Map datas to the object
		switch (type) {
			// When we create a "route" => A page
			case "route":
				returnObject = {
					rank,
					type: "route",
					subType: "chart",
					name,
					route: `/${name.toLowerCase().trim().replaceAll(" ", "-")}`,
					icon,
					filter: filterCode,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			case "form":
				returnObject = {
					rank,
					type: "route",
					subType: "form",
					name,
					route: `/${name.toLowerCase().trim().replaceAll(" ", "-")}`,
					icon,
					form,
					filter: filterCode,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			// When we create a title
			case "title":
				returnObject = {
					rank,
					type,
					name,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			// When we create a divider
			case "divider":
				returnObject = {
					rank,
					type,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			default:
				break;
		}

		// 3- If the user set some permissions, need to save them
		if (!lod_.isEmpty(rolesList)) {
			// 3.1- First, create the custom permissions for the page
			// 3.2- Then, get the new permissions list and update the profile

			/* Handler when getting new permissions */
			const getCustomPermissionsSuccess = res => {
				// Update store with the new permissions
				dispatch(updatePermissions(res.permissions));
				handleSave(returnObject);
				close();
			};
			/* Handler when saving permissions */
			const createCustomPermissionSuccess = res => {
				// Get the new permissions list
				dispatch(
					ProfileActions.getCustomPermissions(
						profile.assistantID,
						profile.role,
						getCustomPermissionsSuccess
					)
				);
			};
			// Save the custom permissions for the page, for all the selected roles
			dispatch(
				ProfileActions.createCustomPermission(
					profile.assistantID,
					rolesList,
					process.env.REACT_APP_APPNAME,
					pagePermission,
					createCustomPermissionSuccess
				)
			);
		} else {
			handleSave(returnObject);
			close();
		}
	}

	function submitEdit() {
		// Get the current page permission or create a new one
		let pagePermission = page?.permissions?.length
			? page.permissions[0]
			: createPagePermission(page.type, name, rolesList);

		// Update the roles by page permission with the new roles list
		dispatch(
			ProfileActions.updateRolesByPagePermission(
				profile.assistantID,
				pagePermission,
				rolesList,
				res => {}
			)
		);

		/* Handler when getting new permissions */
		const getCustomPermissionsSuccess = res => {
			// Update store with the new permissions
			dispatch(updatePermissions(res.permissions));
		};

		// Get the new permissions list
		dispatch(
			ProfileActions.getCustomPermissions(
				profile.assistantID,
				profile.role,
				getCustomPermissionsSuccess
			)
		);

		// Update new page object
		if (!rolesList || lod_.isEmpty(rolesList)) {
			pagePermission = [];
		} else {
			pagePermission = [pagePermission];
		}

		let copy = page;
		if (name) {
			copy.name = name;
		}
		copy.icon = icon;
		copy.filter = filterCode;
		copy.permissions = pagePermission;
		copy.form = form;
		delete copy.open;
		handleSave(copy);
		close(true);
	}
	/**
	 * When user select / deselect a role in the list
	 */
	function updateRolesList(newRole) {
		if (rolesList.includes(newRole)) {
			setRolesList(rolesList.filter(role => role !== newRole));
		} else {
			setRolesList([...rolesList, newRole]);
		}
	}
	/**
	 * Load default roles list (from KM)
	 */
	function loadRoles() {
		dispatch(
			SettingsActions.getRoles(res => {
				// Sort by type
				res.roles.sort((a, b) => {
					if (a.type < b.type) return -1;
					if (a.type > b.type) return 1;
					return 0;
				});
				setDefaultRolesList(res.roles);
			})
		);
	}

	const loadResources = () => {
		dispatch(
			FormActions.getItemsFromCollection("resource", {}, res => {
				setResources(res.items);
			})
		);
	};

	/**
	 * Get the permissions of the page (if exists)
	 * and load all the roles that have this permission
	 */
	function loadRolesByPagePermission(page) {
		if (!page) return;

		let pagePermissions = page.permissions || [];

		let promises = [];
		let tempRolesList = [];

		for (let perm of pagePermissions) {
			promises.push(
				new Promise((resolve, reject) => {
					dispatch(
						ProfileActions.getRolesByPagePermission(profile.assistantID, perm, res => {
							let roles = res.roles.map(role => role.role);
							tempRolesList = [...tempRolesList, ...roles];
							resolve();
						})
					);
				})
			);
		}

		Promise.all(promises).then(() => {
			setRolesList(tempRolesList);
		});
	}

	/**
	 * Load roles list on start
	 */
	useEffect(() => {
		loadRoles();
		loadResources();
		if (page && page.open) {
			if (page.form) {
				setType("form");
			} else {
				setType(page.type);
			}
			setName(page.name);
			setIcon(page.icon);
			let realCode = filtersList.find(filter => filter.name === page.filter);
			setFilterCode(realCode?.code ?? page.filter);
			setForm(page.form);
			loadRolesByPagePermission(page);
		}
	}, [open]);

	return (
		<Dialog
			fullWidth
			maxWidth="xxl"
			open={open}
			onClose={close}
			PaperProps={{
				style: {
					height: "100%",
					width: "100%",
					maxWidth: "100%"
				}
			}}
		>
			<MDBox display="flex">
				<MDBox flex="2">
					{/* <DialogTitle>{i18n.t("SETTINGS.PAGES.addPage")}</DialogTitle> */}
					<DialogContent sx={{ height: "85vh" }}>
						{/* Display title */}
						<MDBox sx={{ mt: 1, mb: 3 }}>
							<MDTypography variant="h5">1- Affichage</MDTypography>
							<MDTypography variant="body2" fontSize="medium">
								{`Paramètres d'affichage de la page`}
							</MDTypography>
						</MDBox>

						{/* Display form */}
						<MDBox mt={1}>
							<FormControl fullWidth>
								<InputLabel
									style={{
										padding: "12px !important"
									}}
									id="select-type"
								>
									{i18n.t("SETTINGS.PAGES.type")}
								</InputLabel>
								{/* Cannot change type when editing page */}
								<Select
									labelId="select-type"
									value={type}
									label={i18n.t("SETTINGS.PAGES.type")}
									onChange={e => {
										setType(e.target.value);
									}}
									disabled={page}
								>
									<MenuItem value="route">
										<MDBox>
											<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.route")}</MDTypography>
											<MDTypography variant="body2" fontSize="small">
												{i18n.t("SETTINGS.PAGES.routeDescription")}
											</MDTypography>
										</MDBox>
									</MenuItem>
									<MenuItem value="form">
										<MDBox>
											<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.form")}</MDTypography>
											<MDTypography variant="body2" fontSize="small">
												{i18n.t("SETTINGS.PAGES.formDescription")}
											</MDTypography>
										</MDBox>
									</MenuItem>
									<MenuItem value="title">
										<MDBox>
											<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.title")}</MDTypography>
											<MDTypography variant="body2" fontSize="small">
												{i18n.t("SETTINGS.PAGES.titleDescription")}
											</MDTypography>
										</MDBox>
									</MenuItem>
									<MenuItem value="divider">
										<MDBox>
											<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.divider")}</MDTypography>
											<MDTypography variant="body2" fontSize="small">
												{i18n.t("SETTINGS.PAGES.dividerDescription")}
											</MDTypography>
										</MDBox>
									</MenuItem>
								</Select>
							</FormControl>
						</MDBox>

						{type !== "divider" && (
							<MDBox mt={1}>
								<MDInput
									disabled={type === "form"}
									className="dialogInput"
									label={i18n.t("SETTINGS.PAGES.name")}
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</MDBox>
						)}
						{(type === "route" || type === "form") && (
							<>
								{/* Icons */}
								<MDBox display="flex" alignItems="flex-start " flexDirection="column">
									<MDBox mt={1} width="100%">
										<IconsList icon={icon} setIcon={setIcon} />
									</MDBox>
								</MDBox>

								{/* Filters */}
								<Divider />
								<MDBox sx={{ mt: 3, mb: 3 }}>
									<MDTypography variant="h5">2- Filtres</MDTypography>
									<MDTypography variant="body2" fontSize="medium">
										Ajoute un filtre à la page
									</MDTypography>
								</MDBox>

								<MDBox mt={1}>
									<FormControl fullWidth>
										<InputLabel id="select-type">{i18n.t("SETTINGS.PAGES.filter")}</InputLabel>
										<Select
											labelId="select-type"
											value={filterCode || "none"}
											label={i18n.t("SETTINGS.PAGES.filter")}
											onChange={e => {
												if (e.target.value === "none") setFilterCode(null);
												else setFilterCode(e.target.value);
											}}
										>
											<MenuItem value="none">{i18n.t("SETTINGS.PAGES.none")}</MenuItem>
											{filtersList &&
												filtersList.map(filter => {
													return <MenuItem value={filter.code}>{filter.name}</MenuItem>;
												})}
										</Select>
									</FormControl>
								</MDBox>

								{/* Form */}
								{type === "form" && (
									<>
										{/* Resource */}
										<Divider />
										<MDBox sx={{ mt: 3, mb: 3 }}>
											<MDTypography variant="h5">3- Ressource</MDTypography>
											<MDTypography variant="body2" fontSize="medium">
												Choix de la ressource à afficher
											</MDTypography>
										</MDBox>

										<MDBox mt={1}>
											<FormControl fullWidth>
												<InputLabel id="select-label">Ressource</InputLabel>
												<Select
													labelId="select-ressource-form"
													value={form.resource || ""}
													label="Ressource"
													onChange={e => {
														let resource = resources.find(r => r.code === e.target.value);
														setForm({
															active: true,
															resource: resource.code,
															collectionDefault: resource.storage.collection,
															routeDictionary: resource.format.dictionary
														});
														setName(resource.name);
													}}
												>
													{resources &&
														resources.map((resource, index) => {
															return (
																<MenuItem
																	key={index}
																	value={resource.code}
																	disabled={activeResources.includes(resource.code)}
																>
																	<MDBox>
																		<MDTypography variant="h6">{resource.name}</MDTypography>
																		<MDTypography variant="body2" fontSize="small">
																			{resource.description}
																		</MDTypography>
																	</MDBox>
																</MenuItem>
															);
														})}
												</Select>
											</FormControl>
										</MDBox>
									</>
								)}
							</>
						)}
					</DialogContent>
					<DialogActions></DialogActions>
				</MDBox>
				<MDBox ml={2} pr={4} flex="1">
					<DialogTitle>{i18n.t("SETTINGS.PAGES.visibleBy")}</DialogTitle>
					<MDTypography variant="body2" fontSize="small">
						{`Si aucun rôle n'est sélectionné, la page sera visible par tous les utilisateurs`}
					</MDTypography>
					<DialogContent sx={{ height: "78vh" }}>
						{defaultRolesList &&
							defaultRolesList.map((perm, index) => {
								return (
									<RolesBox
										key={index}
										type={perm.type}
										name={perm.name}
										code={perm.code}
										rolesList={rolesList}
										onChange={perm => {
											updateRolesList(perm);
										}}
									/>
								);
							})}
					</DialogContent>
					<DialogActions>
						<MDButton variant="outlined" color="info" onClick={close}>
							{i18n.t("SETTINGS.cancel")}
						</MDButton>
						<MDButton
							disabled={isDisabled}
							variant="contained"
							color="info"
							onClick={page ? submitEdit : submitSave}
						>
							{page ? i18n.t("SETTINGS.edit") : i18n.t("SETTINGS.add")}
						</MDButton>
					</DialogActions>
				</MDBox>
			</MDBox>
		</Dialog>
	);
}
