import { api } from "./api";

const actions = {
	/* Clean all files */
	cleanAllFiles: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/clean/${assistantID}`,
					onSuccess
				})
			);
		};
	},
	/* Clean a dialog */
	cleanFile: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/clean/${assistantID}/${code}`,
					onSuccess
				})
			);
		};
	},
	/* Categoryze all files */
	categoryzeAllFiles: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/categoryze/${assistantID}`,
					onSuccess
				})
			);
		};
	},
	/* Analyze one file */
	analyzeFile: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/analyze/${assistantID}/${code}`,
					onSuccess
				})
			);
		};
	},
	/* Analyze all files */
	analyzeAllFiles: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/analyze/${assistantID}`,
					onSuccess
				})
			);
		};
	},
	sendFeedback: (assistantID, fID, element, feedback, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					data: {
						fID,
						element,
						feedback
					},
					url: `/api/v1/files/feedback/${assistantID}`,
					onSuccess
				})
			);
		};
	},
	changeReviewState: (assistantID, code, reviewState, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					data: {
						code,
						reviewState
					},
					url: `/api/v1/files/reviewState/${assistantID}`,
					onSuccess
				})
			);
		};
	},
	/* Customer rework */
	customerRework: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/customer/rework/${assistantID}/${code}`,
					onSuccess
				})
			);
		};
	},
	/* Analyze actions */
	analyzeActions: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/analyze/actions/${assistantID}/${code}`,
					onSuccess
				})
			);
		};
	},
	/* Analyze products */
	analyzeProducts: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/files/analyze/products/${assistantID}/${code}`,
					onSuccess
				})
			);
		};
	}
};

export default actions;
