/* eslint-disable no-underscore-dangle */
/* eslint-disable no-continue */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/**
 * Main application charts
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import SettingsActions from "redux-react/actions/settingsActions";
import FormActions from "redux-react/actions/formAction";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { getInterval } from "components/Custom/DatePresets";
import { setFilter } from "redux-react/reducers/filtersReducers";
import i18n from "i18n";
import lod_ from "lodash";
import { display } from "redux-react/reducers/snackBarReducer";
import MDButton from "components/Basics/MDButton";
import DictionaryDataTable from "components/Custom/Tables/DictionaryDataTable";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import FormDictionary from "pages/settings/others/Dictionary/FormDictionary";
import { AddChartEnyity } from "pages/settings/others";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import FormDictionaryJSON from "pages/settings/others/Dictionary/FormDictionaryJSON";
import { WidthFull } from "@mui/icons-material";

/**
 * Default component to display charts, works with dynamic datas
 */
export default function DicoPage({ route }) {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);
	const filters = useSelector(state => state.filters);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(true);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);
	// When charts are loaded for the first time, avoid multiples requests
	const [firstLoad, setFirstLoad] = useState(true);
	// Form
	const [openFormDialog, setOpenFormDialog] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [selectedId, setSelectedId] = useState(null);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [listAPIs, setlistAPIs] = useState([]);

	const [addFromJSON, setAddFromJSON] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [datas, setDatas] = useState({});

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.customType || chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					null,
					res => resolve(res.charts)
				)
			);
		});
	}
	/* Get filters from back */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/* Build default filters */
	function buildDefaultFilters(defaultFilters) {
		let returnObject = {};

		for (let filter of defaultFilters) {
			// If filter has no default value, continue
			if (!filter.defaultValue) {
				continue;
			}
			// Set default value by type
			switch (filter.type) {
				// Datetime
				case "datetime": {
					// Get value & interval
					let defaultValue = filter.defaultValue;
					// Set datetime filter to store, to display it on the page
					dispatch(
						setFilter({
							// assistantID: profile.assistantID,
							// page: route.route,
							// attribute: filter.attribute,
							// front: interval,
							// back: {
							// 	name: filter.attribute,
							// 	value: interval,
							// 	type: "datetime"
							// }
							assistantID: profile.assistantID,
							page: route.route,
							attribute: filter.attribute,
							// front: datePack,
							type: "datetime",
							mode: "auto",
							code: defaultValue
						})
					);

					let interval = getInterval(defaultValue);
					// Mapped value
					returnObject[filter.attribute] = {
						name: filter.attribute,
						type: filter.type,
						value: interval
					};
					break;
				}
				default:
					break;
			}
		}
		return returnObject;
	}
	/* Load charts from back */
	async function loadCharts(defaultFilters = null) {
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// If there is default filters, set them (ONLY ON PAGE LOAD)
		if (!lod_.isEmpty(defaultFilters)) {
			Object.keys(actualFilters).map(key => {
				let filter = actualFilters[key];
				let defaultFilter = defaultFilters[key];
				// Replace value if it is empty
				if (lod_.isEmpty(filter?.values)) {
					actualFilters[key] = {
						...defaultFilter
					};
				}
			});
		}
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		setChartsLoading(false);
	}

	const dictionaryAddDatas = datas => {
		setSelectedId(null);
		const onSuccess = res => {
			let values = res.valueDictionary;

			values = {
				source: "custom",
				...values,
				...datas
				// code: uuidv4()
			};
			setlistAPIs(res.APIs);
			setEmpty(true);
			setValuesForm(values);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setSelectedTarget("dictionary");
			setRouteDict("dictionary");
			setOpenFormDialog(true);
		};

		dispatch(FormActions.getItemEmpty("dictionary", "dictionary", onSuccess));
	};

	const actionEditHandle = (item, target) => {
		const onSuccess = res => {
			setOpenFormDialog(true);
			setEmpty(false);
			setlistAPIs(res.APIs);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(false);
			setSelectedId(item._id);
			setSelectedTarget("dictionary");
			setRouteDict("dictionary");
		};
		dispatch(FormActions.getItemByID(item._id, "dictionary", "dictionary", onSuccess));
	};

	const actionSaveDialog = (values, unique, callback) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setOpenFormDialog(false);
			callback(true);
			loadCharts();
		};

		let data = { values, target: selectedTarget, unique };
		if (empty) {
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const deleteItem = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			loadCharts();
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			dispatch(FormActions.deleteItem(selectedItem._id, selectedTarget, {}, onSuccess));
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const submit = () => {
		loadCharts();
	};

	const insertToJson = () => {
		setAddFromJSON(true);
	};

	const submitJSON = ({ valid, data }) => {
		setDatas(data);
		setIsValid(valid);
	};

	const clickSubmit = () => {
		const onSuccess = res => {
			setAddFromJSON(false);
			dispatch(
				display({
					message: i18n.t("SETTINGS.DICTIONARY.CALL.successCreate"),
					type: "success"
				})
			);
			loadCharts();
		};

		dispatch(SettingsActions.insertDictionaryFromJSON(profile.assistantID, datas, onSuccess));
	};

	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setFirstLoad(true);
		setChartsLoading(true);

		async function load() {
			// Set page filters
			let pageFilters = await getPageFilters();
			setPageFilters(pageFilters);
			// Load the charts with default filters
			let defaultFilters = buildDefaultFilters(pageFilters);
			loadCharts(defaultFilters);
			// Avoid multiples requests
			setFirstLoad(false);
		}

		load();
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * When filters change, reload charts
	 */
	useEffect(() => {
		if (!firstLoad) {
			loadCharts();
		}
	}, [filters]);
	/* Charts loader */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else {
		/* Main component */
		return (
			<DashboardLayout>
				<MDBox py={3}>
					{pageFilters && (
						<DashboardNavbar
							filters={[
								<MDBox display="flex" alignItems="center">
									<MDBox>
										<MDButton variant="contained" color="info" onClick={insertToJson}>
											<Icon>add</Icon>&nbsp; {i18n.t("SETTINGS.DICTIONARY.addDictionary")}
										</MDButton>
									</MDBox>
								</MDBox>
							]}
						/>
					)}
				</MDBox>

				{addFromJSON && (
					<Dialog fullWidth maxWidth="md" open={addFromJSON} onClose={() => setAddFromJSON(false)}>
						<DialogContent>
							<FormDictionaryJSON submit={submitJSON} />
						</DialogContent>
						<DialogActions>
							<MDButton variant="outlined" color="info" onClick={() => setAddFromJSON(false)}>
								{i18n.t("SETTINGS.cancel")}
							</MDButton>

							<MDButton variant="contained" color="info" onClick={clickSubmit} disabled={!isValid}>
								{i18n.t("SETTINGS.add")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}

				{openFormDialog && !lod_.isEmpty(valuesForm) && (
					<FormDictionaryDialog
						open={openFormDialog}
						route={route}
						handleCloseDialog={() => setOpenFormDialog(false)}
						handleSave={(values, unique, callback) => {
							actionSaveDialog(values, unique, callback);
						}}
						valuesDictionary={valuesForm}
						formBuildDictionary={formBuild}
						contextDictionary={contextDictionary}
						PIaccess={PI}
						isEmpty={empty}
						target={selectedTarget}
						selectedId={selectedId}
						routeDict={routeDict}
						listAPIs={listAPIs}
						forceEdit={Boolean(routeDict === "dictionary")}
					/>
				)}

				{/* Dictionaries */}
				{getChartsData(["dictionary"]).map((chart, index) => {
					return (
						<MDBox mb={2}>
							<DictionaryDataTable
								draggable
								display={chart.request.attributesDisplay}
								dictionary={chart?.data?.dictionary}
								title={chart.display?.title}
								dictionaryCode={chart.code}
								actions={[
									<Tooltip placement="top" title={i18n.t("SETTINGS.edit")}>
										<IconButton
											handleclick={(object, event) => {
												event.stopPropagation();
												actionEditHandle(object, chart.request.collection);
											}}
										>
											<Icon fontSize="medium">edit</Icon>
										</IconButton>
									</Tooltip>,
									<Tooltip placement="top" title={i18n.t("SETTINGS.delete")}>
										<IconButton
											handleclick={(object, event) => {
												event.stopPropagation();
												actionDeleteHandle(object, chart.request.collection);
											}}
										>
											<Icon fontSize="medium">delete</Icon>
										</IconButton>
									</Tooltip>
								]}
								dictionaryFormat={chart.dictionary?.dictionary}
								handleAddRow={datas => {
									dictionaryAddDatas(datas);
								}}
							/>
						</MDBox>
					);
				})}
				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
						<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
						<DialogContent>{i18n.t("FORMS.LABELS.confirmDelete")}</DialogContent>
						<DialogActions>
							<MDButton
								autoFocus
								onClick={() => setConfirmDelete(false)}
								variant="outlined"
								color="info"
							>
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteItem} color="info" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
			</DashboardLayout>
		);
	}
}
