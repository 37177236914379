/* eslint-disable prefer-destructuring */
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

const Message = ({ message }) => {
	let styleDirection = {};

	let header = message.header;
	let payload = message.payload;

	if (header.source === "contact") {
		styleDirection = {
			left: 10,
			maxWidth: "80%",
			marginRight: "20%",
			backgroundColor: "white",
			borderRadius: "15px 15px 15px 0",
			padding: 10
		};
	} else if (header.source === "cockpit") {
		styleDirection = {
			right: 10,
			maxWidth: "80%",
			marginLeft: "20%",
			backgroundColor: "#2196f3",
			color: "white",
			fontWeight: "bold",
			borderRadius: "15px 15px 0 15px",
			padding: 10
		};
	}

	// if (hidden === true) {
	// 	styleDirection.backgroundColor = "#d6d6d6";
	// 	styleDirection.fontStyle = "italic";
	// 	styleDirection.fontWeight = "normal";
	// 	styleDirection.color = "inherit";
	// }

	return (
		<MDBox mt={1} style={styleDirection}>
			{/* {hidden && <MDTypography className="phoneCallMessageHiddenText">Message caché</MDTypography>} */}
			<p
				style={{
					fontSize: 12,
					whiteSpace: "pre-wrap"
				}}
			>
				{payload.text}
			</p>
		</MDBox>
	);
};

export default Message;
